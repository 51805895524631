<template>
  <div class="playback">playback</div>
</template>

<script>

export default {
  name: 'playback',
  components: {
  },
  data() {
    return {
      tableData: [{
        number: 1,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }, {
        number: 2,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }, {
        number: 3,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }, {
        number: 4,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }, {
        number: 5,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }, {
        number: 6,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }, {
        number: 7,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }, {
        number: 8,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }, {
        number: 9,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }, {
        number: 10,
        userName: '测试账号111',
        iphone: '123****5896',
        origin: '12320中心医院',
        destination: '长沙市中心医院',
        TransportType: '特殊转运',
        orderTime: '2020-12-23 12:23:12',
        appointmentTime: '2020-12-23 12:23:12',
        remove: ''
      }],
      currentPage4: 1,
      total: 10
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
